module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-XHPV06ELL1","head":false,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Source Code Pro","file":"https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap"},{"name":"Inter","file":"https://fonts.googleapis.com/css2?family=Inter&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lalaine Portfolio","short_name":"Lalaine Ulit-Destajo Portfolio","start_url":"/","background_color":"#020426","theme_color":"#05a696","display":"standalone","description":"Portfolio website of Lalaine Ulit-Destajo. New Media Artist. Interactive Designer. Creative Coder.","icon":"src/assets/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d3f753b834af9dc73e71ce522f45ab4e"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
